<template>
<div class="loading_wrapper">
    <img src="../../../public/images/loading.gif" alt="">
</div>
</template>
<script>
export default{
  
}
</script>
<style>
.loading_wrapper{
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 99998;
    left: 0;
    top: 0;
}
.loading_wrapper img{
    width: 10rem;
    height: 10rem;
    position: fixed;
    z-index: 99999;
    left: 50%;
    top: 50%;
    margin-left: -5rem;
    margin-top: -5rem;
}
</style>